// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-articles-clinical-research-organizations-in-nigeria-js": () => import("./../../../src/pages/Articles/Clinical-Research-Organizations-in-Nigeria.js" /* webpackChunkName: "component---src-pages-articles-clinical-research-organizations-in-nigeria-js" */),
  "component---src-pages-articles-clinical-research-services-in-nigeria-js": () => import("./../../../src/pages/Articles/Clinical-Research-Services-in-Nigeria.js" /* webpackChunkName: "component---src-pages-articles-clinical-research-services-in-nigeria-js" */),
  "component---src-pages-articles-clinical-trials-and-contract-research-in-nigeria-js": () => import("./../../../src/pages/Articles/Clinical-Trials-and-Contract-Research-In-Nigeria.js" /* webpackChunkName: "component---src-pages-articles-clinical-trials-and-contract-research-in-nigeria-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/Articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-articles-partnerships-and-collaborations-the-right-alliances-for-clinical-trials-in-africa-js": () => import("./../../../src/pages/Articles/Partnerships-and-Collaborations-The-Right-Alliances-for-Clinical-Trials-in-Africa.js" /* webpackChunkName: "component---src-pages-articles-partnerships-and-collaborations-the-right-alliances-for-clinical-trials-in-africa-js" */),
  "component---src-pages-articles-product-registration-in-cemac-ecowas-and-uemoa-regions-js": () => import("./../../../src/pages/Articles/Product-Registration-in-CEMAC-ECOWAS-and-UEMOA-Regions.js" /* webpackChunkName: "component---src-pages-articles-product-registration-in-cemac-ecowas-and-uemoa-regions-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/Home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-other-privacy-policy-js": () => import("./../../../src/pages/other/privacy-policy.js" /* webpackChunkName: "component---src-pages-other-privacy-policy-js" */),
  "component---src-pages-other-sitemap-js": () => import("./../../../src/pages/other/sitemap.js" /* webpackChunkName: "component---src-pages-other-sitemap-js" */),
  "component---src-pages-other-thank-you-js": () => import("./../../../src/pages/other/thank-you.js" /* webpackChunkName: "component---src-pages-other-thank-you-js" */),
  "component---src-pages-our-clients-js": () => import("./../../../src/pages/our-clients.js" /* webpackChunkName: "component---src-pages-our-clients-js" */),
  "component---src-pages-services-clinical-trails-js": () => import("./../../../src/pages/services/clinical-trails.js" /* webpackChunkName: "component---src-pages-services-clinical-trails-js" */),
  "component---src-pages-services-our-services-js": () => import("./../../../src/pages/services/our-services.js" /* webpackChunkName: "component---src-pages-services-our-services-js" */),
  "component---src-pages-services-pharmacovigilance-js": () => import("./../../../src/pages/services/pharmacovigilance.js" /* webpackChunkName: "component---src-pages-services-pharmacovigilance-js" */),
  "component---src-pages-services-regulatory-affairs-js": () => import("./../../../src/pages/services/regulatory-affairs.js" /* webpackChunkName: "component---src-pages-services-regulatory-affairs-js" */)
}

